<template>
  <ul class="icon-container">
    <li v-if="!isMobile" @click.stop="clickFn('分享')" class="share">
      <img src="@/assets/svg/svg/icon-01.svg" alt="" />
      <ul :style="{ display: isHot ? 'block' : 'none' }" class="share_box">
        <div class="sanjiao"></div>
        <li @mouseover.stop="weChat" class="weChat">
          <img src="@/assets/img/details/weChat.png" alt="" />
          <div>微信</div>
          <div
            :id="`WXqrcode${curcer}`"
            :ref="`WXqrcode${curcer}`"
            class="ma"
          ></div>
        </li>
        <li @click.stop="weibo">
          <img src="@/assets/img/details/microblog.png" alt="" />
          <div>新浪微博</div>
        </li>
        <li @mouseover.stop="qq" class="qq">
          <img src="@/assets/img/details/QQ.png" alt="" />
          <div>QQ</div>
          <div
            :id="`QQqrcode${curcer}`"
            :ref="`QQqrcode${curcer}`"
            class="ma"
          ></div>
        </li>
        <li @click.stop="space">
          <img src="@/assets/img/details/qqSpace.png" alt="" />
          <div>QQ空间</div>
        </li>
      </ul>
    </li>
    <li v-if="!isMobile" @click.stop="clickFn('收藏')">
      <img src="@/assets/svg/svg/icon-08.svg" v-if="every.isCollect" alt="" />
      <img v-else src="@/assets/svg/svg/icon-02.svg" alt="" />
    </li>
    <li>
      <Button
        class="imgBtn"
        type="text"
        @click.stop="clickFn('下载')"
        v-throttleClick
      >
        <img src="@/assets/svg/svg/icon-03.svg" alt="" />
      </Button>
    </li>
    <li class="read" v-if="!isMobile">
      <Button
        class="imgBtn"
        type="text"
        @click.stop="clickFn('在线阅读')"
        v-throttleClick
      >
        <img src="@/assets/svg/svg/icon-04.svg" alt="" />
      </Button>
    </li>
  </ul>
</template>

<script>
import { mapState } from "vuex";
import QRCode from "qrcodejs2";
export default {
  props: {
    every: {
      type: Object,
      required: true,
    },
    curcer: {
      type: String,
    },
  },
  computed: {
    ...mapState(["hasLogin", "token"]),
  },
  data() {
    return {
      WXurl: "",
      QQurl: "",
      isHot: false,
      isMobile: window.navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      ),
    };
  },
  methods: {
    handleClick() {
      this.$emit("itemClick", this.every);
    },
    async read(type) {
      if (!this.hasLogin) {
        this.$store.commit("showAlert", true);
        return;
      }
      let obj = {
        articleId: this.curcer,
        articleType: type,
      };
      let res = await this.$apis.detailServe.hasFile(obj);
      if (res.resultCode !== "200") {
        this.$Message.error("没有文献");
        return false;
      }
      window.open(
        this.$apis.searchServe.readUrl +
          "?articleId=" +
          this.curcer +
          "&articleType=" +
          type +
          "&token=" +
          this.token
      );
    },
    async downPdf(type) {
      if (!this.hasLogin) {
        this.$store.commit("showAlert", true);
        return;
      }
      let obj = {
        articleId: this.curcer,
        articleType: type,
      };
      let res = await this.$apis.detailServe.hasFile(obj);
      if (res.resultCode !== "200") {
        this.$Message.error("没有文献");
        return false;
      }
      window.open(
        this.$apis.searchServe.download +
          "?articleId=" +
          this.curcer +
          "&articleType=" +
          type +
          "&token=" +
          this.token
      );
    },

    downPdfNotoken(type) {
      window.open(
        this.$apis.searchServe.downloadNoToken +
          "?articleId=" +
          this.curcer +
          "&articleType=" +
          type
      );
    },

    readNotoken(type) {
      window.open(
        this.$apis.searchServe.readUrlNoToken +
          "?articleId=" +
          this.curcer +
          "&articleType=" +
          type
      );
    },

    clickFn(item) {
      if (item === "收藏") {
        this.collect(this.every);
      } else if (item === "下载") {
        this.isMobile
          ? this.downPdfNotoken(this.every.articleType)
          : this.downPdf(this.every.articleType);
      } else if (item === "在线阅读") {
        this.isMobile
          ? this.readNotoken(this.every.articleType)
          : this.read(this.every.articleType);
      } else if (item === "分享") {
        this.isHot = true;
      }
    },
    // 收藏
    async collect() {
      if (!this.hasLogin) {
        this.$store.commit("showAlert", true);
        return;
      }
      let obj = {
        action: this.every.isCollect ? 0 : 1,
        articleId: this.curcer,
        articleType: this.every.articleType,
      };
      await this.$apis.searchServe.CollectItem(obj);
      await this.TFCollect();
    },
    async TFCollect() {
      if (!this.hasLogin) {
        this.$store.commit("showAlert", true);
        return;
      }
      let obj = {
        articleId: this.curcer,
        articleType: this.every.articleType,
      };
      let res = await this.$apis.searchServe.isCollect(obj);
      this.every.isCollect = res.isCollect;
    },
    getTitle() {
      let title = "";
      switch (this.every.articleType) {
        case 0:
        case 5:
          title = this.every.data.title;
          break;
        case 1:
          title = this.every.data.patentName;
          break;
        case 2:
          title = this.every.data.monographName || this.every.data.title;
          break;
        case 3:
          title = this.every.data.reportName;
          break;
        case 4:
          title = this.every.data.standardName;
          break;
      }
      return title;
    },
    getUrl() {
      let url = "";
      if (this.every.articleType == 0 && this.every.type == 0) {
        url =
          this.$global.url3 +
          `/paperdetail?articleType=${this.every.articleType}&articleId=${this.curcer}`;
      } else if (this.every.articleType == 0 && this.every.type == 1) {
        url =
          this.$global.url3 +
          `/conferencepapers?articleType=${this.every.articleType}&articleId=${this.curcer}`;
      } else if (this.every.articleType == 0 && this.every.type == 2) {
        url =
          this.$global.url3 +
          `/conferenceppt?articleType=${this.every.articleType}&articleId=${this.curcer}`;
      } else if (this.every.articleType == 0 && this.every.type == 4) {
        url =
          this.$global.url3 +
          `/conferenceother?articleType=${this.every.articleType}&articleId=${this.curcer}`;
      } else if (this.every.articleType == 1) {
        url =
          this.$global.url3 +
          `/patentdetail?articleType=${this.every.articleType}&articleId=${this.curcer}`;
      } else if (this.every.articleType == 2) {
        url =
          this.$global.url3 +
          `/monographdetail?articleType=${this.every.articleType}&articleId=${this.curcer}`;
      } else if (this.every.articleType == 3) {
        url =
          this.$global.url3 +
          `/reportdetail?articleType=${this.every.articleType}&articleId=${this.curcer}`;
      } else if (this.every.articleType == 4) {
        url =
          this.$global.url3 +
          `/standarddetail?articleType=${this.every.articleType}&articleId=${this.curcer}`;
      }
      return url;
    },
    weChat() {
      if (this.WXurl !== "") return;
      this.WXurl = this.getUrl();
      // 使用$nextTick确保数据渲染
      this.$nextTick(() => {
        this.WXcrateQrcode();
      });
    },
    WXcrateQrcode() {
      this.qr = new QRCode("WXqrcode" + this.curcer, {
        width: 150,
        height: 150, // 高度
        text: this.WXurl, // 二维码内容
      });
    },
    QQcrateQrcode() {
      this.qr = new QRCode("QQqrcode" + this.curcer, {
        width: 150,
        height: 150, // 高度
        text: this.QQurl, // 二维码内容
      });
    },
    qq() {
      if (this.QQurl !== "") return;
      this.QQurl = this.getUrl();
      // 使用$nextTick确保数据渲染
      this.$nextTick(() => {
        this.QQcrateQrcode();
      });
    },
    weibo() {
      let url = encodeURIComponent(this.getUrl());
      let srcUrl = "";
      this.every.cover
        ? (srcUrl = `pic=${this.$global.imgUrl + this.every.cover}&`)
        : (srcUrl = "");
      window.open(
        `https://service.weibo.com/share/share.php?${srcUrl}url=${url}&title=${this.every.title}`
      );
    },
    space() {
      let url = encodeURIComponent(this.getUrl());
      let srcUrl = "";
      this.every.cover
        ? (srcUrl = `pics=${this.$global.imgUrl + this.every.cover}&`)
        : (srcUrl = "");
      window.open(
        `https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?${srcUrl}url=${url}&title=${this.every.title}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-container {
  margin-top: 16px;
  display: flex;
  li {
    width: 63px;
    height: 24px;
    margin-right: 13px;
    cursor: pointer;
    .imgBtn {
      width: 63px;
      height: 24px;
      margin: 0;
      padding: 0;
      border: none;
      &:focus {
        box-shadow: none !important;
      }
      img {
        width: 63px;
        height: 24px;
      }
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .read {
    width: 95px;
    height: 24px;
    .imgBtn {
      width: 95px;
      height: 24px;
      img {
        width: 95px;
        height: 24px;
      }
    }
  }
  // .share:hover {
  //   .share_box {
  //     display: block;
  //   }
  // }
  .share {
    position: relative;
    .share_box {
      cursor: pointer;
      display: none;
      right: -133px;
      top: -130px;
      position: absolute;
      width: 133px;
      height: 167px;
      background: #ffffff;
      box-shadow: 3px 1px 7px 0px rgba(8, 8, 64, 0.23);
      border-radius: 10px;
      .weChat:hover {
        .ma {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .weChat {
        position: relative;
        .ma {
          display: none;
          left: 123px !important;
          position: absolute !important;
          width: 200px !important;
          top: -10px !important;
          height: 167px !important;
          background: white !important;
          box-shadow: 3px 1px 7px 0px rgba(8, 8, 64, 0.23);
          border-radius: 10px;
        }
      }
      .qq:hover {
        .ma {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .qq {
        position: relative;
        .ma {
          display: none;
          left: 123px !important;
          position: absolute !important;
          width: 200px !important;
          top: -90px !important;
          height: 167px !important;
          background: white !important;
          box-shadow: 3px 1px 7px 0px rgba(8, 8, 64, 0.23);
          border-radius: 10px;
        }
      }
      li {
        margin-top: 10px;
        margin-bottom: 0px;
        display: flex;
        flex-wrap: nowrap;
        //background: pink;
        justify-content: center;
        align-items: center;
        width: 133px;
        height: 30px;

        div {
          width: 60px;
          //background: yellow;
          margin-left: 10px;
        }
        img {
          width: 20px;
          height: 20px;
        }
      }
      .sanjiao {
        width: 0;
        height: 0;
        border-top: 3px solid transparent;
        border-right: 6px solid #fff;
        position: absolute;
        top: 50%;
        margin-top: -3px;
        left: -6px;
        border-bottom: 3px solid transparent;

        //background: pink;
      }
    }
  }
}
</style>
